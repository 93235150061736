import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { loadState, saveState, removeState } from "utils";
import { getCredentials, getUserProfile } from "api/userAPI";

const user = loadState("user");

export const login = createAsyncThunk("user/login", async (param, { signal, rejectWithValue }) => {
  try {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getCredentials(param, {
      cancelToken: source.token,
    });

    if (response?.Status) {
      return response.Data;
    }
  } catch (err) {
    const error = JSON.parse(err);
    if (!error?.Status) {
      return rejectWithValue(error);
    }
    if (axios.isCancel(err)) {
      rejectWithValue(err.message);
    }
  }
});

export const updateUserProfile = createAsyncThunk("user/profile", async (_param, { rejectWithValue, getState }) => {
  try {
    const state = getState()
    const response = await getUserProfile(state.user.user.access_token);

    if (response?.Status) {
      return response.Data;
    }
  } catch (err) {
    const error = JSON.parse(err);
    if (!error?.Status) {
      return rejectWithValue(error);
    }
    if (axios.isCancel(err)) {
      rejectWithValue(err.message);
    }
  }
});

const slice = createSlice({
  name: "user",
  initialState: {
    user: user,
    isAuthenticated: user?.access_token ? true : false,
    expires: user?.expires,
    clientProfiles: user?.Client_Profiles[0],
    loading: "idle",
    error: "",
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.expires = null;
      state.clientProfiles = null;
      state.error = "";
      removeState("user");
    },
    changeDefaultPasswordSuccess: (state) => {
      state.isAuthenticated = state.user?.access_token ? true : false;
      state.expires = state.user?.expires;
      state.clientProfiles = state.user?.Client_Profiles[0];
      saveState(state.user, "user");
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    },
    [login.fulfilled]: (state, { payload }) => {
      if (state.loading === "pending") {
        state.user = payload;
        state.isAuthenticated = state.user?.access_token ? true : false;
        state.expires = state.user?.expires;
        state.clientProfiles = state.user?.Client_Profiles[0];
        state.error = "";
        saveState(state.user, "user");
      }
    },
    [login.rejected]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.error = action.payload.error;
      }
    },
    [updateUserProfile.fulfilled]: (state, { payload }) => {
      if(state?.clientProfiles?.Profile_Active_Status_Id !== payload?.Profile_Active_Status_Id) {
        state.clientProfiles = payload[0];
        state.user.Client_Profiles = payload;
        saveState(state.user, "user");
      }
    }
  },
});

export default slice.reducer;

export const { logout } = slice.actions;

// export const login = (param, source, setSubmitting, history) => async (dispatch) => {
//   try {
//     let cancelToken;
//     if (source) {
//       cancelToken = { cancelToken: source.token };
//     }

//     const res = await getCredentials(param, cancelToken);

//     if (res?.Status) {
//       dispatch(
//         loginSuccess({
//           user: res.Data,
//         })
//       );
//       history.push("/dashboard");
//     }

//     if (!res?.Status) {
//       dispatch(
//         loginFailed({
//           error: res.Data?.error,
//         })
//       );
//     }
//   } catch (error) {
//     if (axios.isCancel(error)) {
//       console.log("cancelled");
//     } else {
//       console.error(error);
//     }
//   } finally {
//     setSubmitting(false);
//   }
// };
