import React, { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "components/pages/Home/Home";
import Services from "components/pages/Services/Services";
import Municipal from "components/pages/Municipal/Municipal";
import ScrollToTop from "./ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";
import SuggestionsComplains from "components/pages/SuggestionsComplains/SuggestionsComplains";
import Login from "components/pages/Login";
import ContactUS from "components/pages/ContactUS/ContactUS";
import PrivacyPolicy from "components/UIComponents/Footer/PrivacyPolicy";
import SliderContent from "components/pages/SliderContent/SliderContent";
import Profile from "components/pages/Profile";
import Dashboard from "components/pages/Dashboard";
import StructuralAuditing from "components/pages/StructuralAuditing/StructuralAuditing";
import ServiceCard from "features/service/ServiceCard";
import ServiceView from "features/service/ServiceView";
import UpdateRequest from "features/service/UpdateRequest";
import UserServicesSearch from "components/pages/UserServicesSearch/UserServicesSearch";
import Invoices from "components/pages/Invoices/Invoices";
import moment from "moment";
import DuePaymentView from "./../components/pages/Dashboard/DuePayments/DuePaymentsView";
import PaymentsView from "./../components/pages/Dashboard/Payments/PaymentsView";
import HousingModels from "./../components/pages/HousingModels/HousingModels";
import HouseModel from "./../components/pages/HousingModels/HouseModel";
import CommonQuestions from "./../components/pages/CommonQuestions/CommonQuestions";
import DecisionsGroup from "./../components/pages/DecisionsGroup/DecisionsGroup";
import ClientRequestInfo from "./../features/service/ClientRequestInfo";
import ErrorToast from "./../components/UIComponents/ErrorToast/ErrorToast";
import AllOffers from "./../components/UIComponents/ToolCards/AllOffers";
import { useSelector, useDispatch } from "react-redux";
import { updateUserProfile } from "features/user/userSlice";
// import PrivacyPolicyModal from './../../src/components/UIComponents/PrivacyPolicyModal'

const App = () => {
  const { i18n } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  let mystyle;
  moment.locale(i18n.language);
  if (i18n.language === "ar") {
    mystyle = {
      direction: "rtl",
      textAlign: "right",
    };
  } else {
    mystyle = {
      direction: "ltr",
      textAlign: "left",
    };
  }

  useEffect(() => {
    let interval = null;
    if (user && user.access_token) {
      const refetchProfile = () => dispatch(updateUserProfile());
      interval = setInterval(refetchProfile, 120000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [user]);

  return (
    <Suspense fallback="loading">
      <ErrorToast />
      {/* <PrivacyPolicyModal /> */}
      <div id="theme" className="mainTheme" style={mystyle}>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />

            <Route exact path="/service-card/:serviceId/:requestDraftId?">
              <ScrollToTop>
                <ServiceCard />
              </ScrollToTop>
            </Route>

            <Route exact path="/service-view/:requestStatusId/:requestId">
              <ScrollToTop>
                <ServiceView />
              </ScrollToTop>
            </Route>

            <Route exact path="/update-request/:taskTypeId/:requestId/:requestTaskId?">
              <ScrollToTop>
                <UpdateRequest />
              </ScrollToTop>
            </Route>

            <Route exact path="/request-info/:requestId">
              <ScrollToTop>
                <ClientRequestInfo />
              </ScrollToTop>
            </Route>

            <Route
              exact
              path="/"
              render={() => <Redirect to="/homePage/:dealerId" />}
            />

            <Route
              exact
              path="/homePage"
              render={() => <Redirect to="/homePage/:dealerId" />}
            />

            <Route exact path="/homePage">
              <Home />
            </Route>

            <Route exact path="/homePage/:dealerId">
              <Home />
            </Route>

            <Route path="/our_services">
              <ScrollToTop>
                <Services />
              </ScrollToTop>
            </Route>

            <Route path="/all_offers">
              <ScrollToTop>
                <AllOffers />
              </ScrollToTop>
            </Route>

            <Route exact path="/municipal/:municipalId/:dealerId">
              <Municipal />
            </Route>

            <Route exact path="/suggestions_and_complains">
              <ScrollToTop>
                <SuggestionsComplains />
              </ScrollToTop>
            </Route>

            <Route exact path="/contact_us/:municipalId?">
              <ScrollToTop>
                <ContactUS />
              </ScrollToTop>
            </Route>

            <Route exact path="/privacy_policy">
              <ScrollToTop>
                <PrivacyPolicy />
              </ScrollToTop>
            </Route>

            <Route exact path="/slider_content">
              <ScrollToTop>
                <SliderContent />
              </ScrollToTop>
            </Route>

            <Route exact path="/house_model/:modelId">
              <ScrollToTop>
                <HouseModel />
              </ScrollToTop>
            </Route>

            <Route exact path="/common_questions">
              <ScrollToTop>
                <CommonQuestions />
              </ScrollToTop>
            </Route>

            <Route exact path="/Profile" component={Profile} />

            <Route exact path="/housing_models" component={HousingModels} />

            <Route exact path="/decisions_group" component={DecisionsGroup} />

            <Route
              exact
              path="/duepayment-view/:invoiceId"
              component={DuePaymentView}
            />

            <Route
              exact
              path="/payments-view/:invoiceId"
              component={PaymentsView}
            />

            <Route exact path="/Dashboard">
              <ScrollToTop>
                <Dashboard />
              </ScrollToTop>
            </Route>

            <Route exact path="/structuralAuditing">
              <ScrollToTop>
                <StructuralAuditing />
              </ScrollToTop>
            </Route>

            <Route
              exact
              path="/UserServicesSearch"
              component={UserServicesSearch}
            />
            <Route exact path="/Invoices" component={Invoices} />

            <Route path="*" component={Home} />
          </Switch>
        </Router>
      </div>
    </Suspense>
  );
};

export default App;

{
  /*
    <Route exact path='/' component={Home} />
    <Route path='/:dealerId' component={Home} />
    <Route path='/our_services' component={Services} />
    <Route exact path='/service_details/:serviceId' component={ServiceDetails} />
    <Route path='/service_request' component={ServiceRequest} /> 
  */
}
